import * as ko from 'knockout';

import { BaseTrialStep } from './base';
import { WizardController } from '../../screens/trial_wizard';
import { canEditLibraryScheduledVisitDays } from '../../permissions';
import { session } from '../../session';
import i18n from '../../i18n';

let template = require('raw-loader!../../../templates/components/trial_wizard/trial_visits.html').default;

class TrialVisits extends BaseTrialStep {
  // @ts-ignore
  private title = session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial())
    ? i18n.t('Step 8 - Scheduled visits')()
    : i18n.t('Step 7 - Scheduled visits')();

  loadingLibrary = ko.observable(false);

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { controller: WizardController }) {
    super(params);
    this.ready(true);
  }

  canEditLibraryDays = ko.pureComputed(
    () =>
      canEditLibraryScheduledVisitDays(session.tenant(), this.trialWizard().trial()) &&
      !this.trialWizard().trial().template
  );

  async reload() {
    super.reload();

    this.dispose();
    this.subscriptions.push(this.trialWizard.subscribe(() => this.reload()));

    if (this.trialWizard().editMode === 'library') {
      this.loadingLibrary(true);
      await this.trialWizard().loadLibrarySV();
      this.loadingLibrary(false);
    }
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
  }

  hasErrors() {
    for (let sv of this.trialWizard().scheduledVisits()) {
      if (sv.hasErrors()) {
        return true;
      }
    }

    return false;
  }

  showErrors() {
    for (let sv of this.trialWizard().scheduledVisits()) {
      sv.showErrors();
    }
  }

  applyLocalValidation() {
    return !this.hasErrors();
  }

  applyServerErrors(errors: any) {
    const svErrors = errors['scheduled_visits'] || [];
    const svs = this.trialWizard().scheduledVisits();

    for (let i = 0; i < svErrors.length; i++) {
      this.applyModelServerErrors(svs[i], svErrors[i]);
    }
  }
}

ko.components.register('trial-visits', {
  viewModel: TrialVisits,
  template: template,
});
